<template>
  <div class="content">
    <div class="title">
      <span class="titlewenzi">{{$t('m.index.ranking_list')}}</span>
      <ul v-show="thisNow==0">
        <li v-for="(i,k) in navList" :key="k">
          <span @click="addclass(k)"
          class="navspan"
          :class="active==k?'active':''"
          >{{i}}</span>
          <i v-if="k!=3">|</i>
        </li>
      </ul>
    </div>
    <div class="meun">
      <div>
        <p @click="tonext(0)" :class="thisNow==0?'cs':''">{{$t('m.index.Variety_transaction_volume')}}</p>
        <span v-show="thisNow==0"></span>
      </div>
      <div>
        <p @click="tonext(1)" :class="thisNow==1?'cs':''">{{$t('m.index.Heat_Ranking')}}</p>
        <span v-show="thisNow==1"></span>
      </div>
      <div>
        <p @click="tonext(2)" :class="thisNow==2?'cs':''">{{$t('m.index.Latest_launch')}}</p>
        <span v-show="thisNow==2"></span>
      </div>
    </div>

    <div class="table_show" v-loading="loading">
      <table id="tfhover" class="tftable" v-if="content.length!==0">
        <tr>
          <th v-for="i in title" :key="i">{{i}}</th>
        </tr>
        <tr class="heng" v-for="(item, index) in content" :key="index">
          <td class="mingci">
            <span class="span_icon" v-if="index===0" style="background: none;">
              <img src="../../../static/ossImg/first.png" alt=""></span>
            <span class="span_icon" v-else-if="index===1" style="background: none;">
              <img src="../../../static/ossImg/second.png" alt=""></span>
            <span class="span_icon" v-else-if="index===2" style="background: none;">
              <img src="../../../static/ossImg/third.png" alt=""></span>
            <span v-else>{{index+1}}</span>
          </td>
          <td>{{item.name}}</td>
          <td>{{thisNow==0?item.money:thisNow==1?item.country:item.stock}}</td>
          <!-- <td>{{'详情'}}</td> -->
        </tr>
      </table>
      <p class="nodata" v-else>{{$t('m.index.No_transaction')}}！</p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
// 首页获取排行榜信息
const getLeaderboard = (data) => {
  return http.get('/mall-portal/home/getLeaderboard', data)
}
export default {
  name: 'Bang',
  data () {
    return {
      active: 3,
      loading: ref(true),
      title: [
        this.$t('m.index.Latest_launch'),
        this.$t('m.index.variety'),
        this.$t('m.index.Transaction_volume')],
      content: [],
      navList: [this.$t('m.index.today'),
        this.$t('m.index.this_week'),
        this.$t('m.index.this_month'),
        this.$t('m.index.accumulate')],
      thisNow: ref(0)
    }
  },
  methods: {
    addclass (e) {
      this.active = e
      this.loading = true
      this.initdata(e)
    },
    async initdata (sd) {
      const data = {
        rankingClassification: sd,
        queryType: 0,
        pageNum: 1,
        pageSize: 6
      }
      this.content = []
      const getList = await getLeaderboard(data)
      // console.log(getList)
      this.content = getList.data.data

      // if (getList.data.data.length > 6) {
      //   for (let index = 0; index < 6; index++) {
      //     this.content.push(getList.data.data[index])
      //   }
      // } else {
      //   this.content = getList.data.data
      // }
      this.loading = false
      // console.log(this.content)
    },
    async tonext (e) {
      this.thisNow = e
      let data = null
      this.loading = true
      if (e === 0) {
        data = {
          rankingClassification: 0,
          queryType: 0,
          pageNum: 1,
          pageSize: 6
        }
        this.title = [this.$t('m.index.Latest_launch'),
          this.$t('m.index.variety'),
          this.$t('m.index.Transaction_volume')]
      } else if (e === 1) {
        data = {
          queryType: 1,
          pageNum: 1,
          pageSize: 6
        }
        this.title = [this.$t('m.index.Latest_launch'),
          this.$t('m.index.variety'),
          this.$t('m.search.supplier')]
      } else {
        data = {
          queryType: 2,
          pageNum: 1,
          pageSize: 6
        }
        this.title = [this.$t('m.index.Latest_launch'),
          this.$t('m.index.variety'),
          this.$t('m.index.inventory')]
      }
      const getList = await getLeaderboard(data)
      console.log(getList)
      if (getList.data.code === 200) {
        this.content = getList.data.data
      }
      this.loading = false
    }
  },
  mounted () {
    this.initdata(this.active)
  },
  setup () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  width: 100%;
  .title{
    position: relative;
    height: 70px;
    background: url('../../../static/imges/bangdan.jpg');
    .titlewenzi{
      display: block;
      float: left;
      height: 70px;
      min-width: 60px;
      line-height: 80px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      margin-left: 70px;
    }
    ul{
      position: absolute;
      right: 0;
      display: block;
      float: right;
      // box-sizing: border-box;
      padding: 5px 10px 0 0;
      li :nth-of-type(1){
        color: #FFFFFF;
      }
      li{
        line-height: 70px;
        float: left;
        span{
          font-size: 14px;
          width: 36px;
          height: 20px;
          padding: 2px 12px;
          border-radius: 4px;
          margin: 0 10px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .active{
          // background: #FFFFFF;
          background: #F3ECDC;
          font-weight: 400;
          color: #5A4944;
        }
        i{
          color: #FFFFFF;
        }
      }
    }
  }
  .meun{
    width: 100%;
    display: flex;
    // flex-direction:row;
    background: #FFF8F2;
    div{
      flex: 1;
      padding: 5px 0;
      p{
        text-align: center;
        margin-bottom: 5px;
        cursor: pointer;
      }
      span{

        display: block;
        margin: 0 auto;
        width: 10px;
        border-bottom: 2px solid #ce9468;
      }
      .cs{
        color: #ce9468;
      }
    }
  }
  .table_show{
    display: flex;
    align-items: center;
    height: 232px;
    overflow: hidden;
    // background: #FFFFFF;
    background: url('../../../static/imges/Bangbg.png') no-repeat;
    background-size:cover;
    .tftable {
      font-size:12px;
      color:#333333;
      width:100%;
      height: 231px;
      // border-collapse: collapse;
      .heng{
        height: 10px !important;
      }
      tr{
          height: 25px;
          // background: #F7F7F7;
        th{
          width: 595px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d6d6d6;
          padding: 8px 10px;
          border-style: solid;
          // border-color: #729ea5;
          text-align:center;
        }
        td{
          text-align:center;

          line-height: 25px;
          font-size:12px;
          padding:3px 8px 3px 12px;
          border-style: solid;
        }
        td:last-child{
          color: #C6824E;
        }
        .mingci{
          span{
            display: inline-block;
            width: 15px;
            height: 15px;
            text-align: center;
            line-height: 15px;
            background: #A9A9A9;
            color: #e9e9e9;
            border-radius: 50%;
            img{
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    .nodata{
      flex: 1;
      font-size: 20px;
      font-weight: bold;
      color: #C6824E;
      text-align: center;
    }
  }
}
</style>
