<template>
<!--  资讯中心 -->
<div class='main'>
  <div class="head">
    <ul>
      <li v-for="(item,index) in headWenzi" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.name}}</i>
      </li>
      <li><a @click="this.$router.push('/coffee/user')">会员</a></li>
      <li><a @click="this.$router.push('/coffee')">咖啡社区</a></li>
      <li><a @click="this.$router.push('/live')">直播</a></li>
    </ul>
  </div>

  <div class="body">
    <!-- <div class="body_title">
      <img class="logo" src="../../static/ossImg/logo.png" alt="">
      <div id="sear">
        <el-input id="searin" v-model="searValue" placeholder="品牌/品种/产地"></el-input>
        <button class="sou" @click="sou">搜索</button>
      </div>
      <img class="logo" src="../../static/ossImg/logo.png" alt="">
    </div> -->

    <div class="body_cont">
      <div class="cont_sear">
        <div class="sear_title">
          <div class="sear_time">
            <!-- <span class="time_ti">世界时钟</span> -->
            <ul>
              <li>
                <el-icon><timer /></el-icon>
                <p style="margin-right: 20px;">{{'北京时间'+' '+nowTime}}</p>
                <iframe name="weather_inc"
                  src="http://i.tianqi.com/?c=code&id=1"
                  width="500" height="20" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
              </li>
            </ul>
          </div>
           <div class="sear_body">
              <div class="sear_left">
                <h3>
                  <el-icon><Management /></el-icon>
                  <strong>要闻快讯</strong>
                </h3>
                 <ul class="left_ul">
                    <li v-for="i in remenList" :key="i" @click="tieziDetails(i)">
                      <el-icon><HelpFilled /></el-icon> {{i.title}}
                    </li>
                 </ul>
              </div>
              <div class="sear_center">
                <h3><img src="../../static/ossImg/coffeCup.png"> 产区直播</h3>
                <div id="video"></div>
              </div>
              <div class="sear_right">
                <!-- <h3><img src="../../static/ossImg/coffeCup.png">咖啡交易排行榜</h3> -->
                <Bang />
              </div>
            </div>
        </div>
      </div>

      <div class="cont_list">
        <div class="list_title">
          <el-row :gutter="20">
            <el-col :span="6"></el-col>
            <el-col :span="4">更新时间</el-col>

            <!-- <el-col :span="1.5">评论</el-col> -->

            <el-col :span="3"></el-col>
            <el-col :span="4">主题</el-col>

            <el-col :span="2"></el-col>
            <el-col :span="3">作者</el-col>

            <el-col :span="1.5">阅读</el-col>
            <!-- <el-col :span="2"></el-col> -->

            <!-- <el-col :span="2">
              <el-button @click="toPublications">发表文章</el-button>
            </el-col> -->
            <!-- <el-col :span="2">
              <el-button>退出登录</el-button>
            </el-col> -->
          </el-row>
        </div>

        <div class="list_body">
          <el-row :gutter="20">
            <el-col :span="3.5" class="left_list">
              <el-tree default-expand-all :data="treeList" @node-click="handleNodeClick"/>
            </el-col>
            <el-col :span="12" class="center_list" v-loading="loadingtieziList">
              <el-row :gutter="12" v-for="i in tieziList" :key="i">
                <el-col :span="5">{{i.establishTime}}</el-col>

                <!-- <el-col :span="2">{{i.replies}}</el-col> -->

                <el-col :span="12" @click="tieziDetails(i)">{{i.title}}</el-col>
                <el-col :span="4">{{i.memberNickName}}</el-col>
                <el-col :span="3">{{i.pageview}}</el-col>

              </el-row>
              <!-- 底部分页 -->
              <div class="showfooter" v-if="totalnum<pagesize?false:true">
                <el-pagination
                  v-model:currentPage="currentPage"
                  :page-size="pagesize"
                  layout="prev, pager, next, jumper"
                  :total="totalnum"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </el-col>
            <el-col :span="8" class="right_list">
              <div class="deallist">
                <div class="deal_title">
                  <strong>最新成交</strong>
                  <span>更多</span>
                </div>
                <div id="table_head">
                  <tr>
                    <th v-for="i in tableTitle" :key="i"
                    :class="i==0?'thname':''">{{i}}</th>
                  </tr>
                </div>
                <vue-seamless-scroll :data="tableCont"
                :class-option="optionHover"
                class="seamless-warp"
                v-loading="loading">
                  <div id="table_body">
                    <table>
                      <tr class="table_tr" v-for="(k,index) in tableCont" :key="index">
                        <td >{{k.productName}}</td>
                        <td style="color:#E90000;">{{k.price}}</td>
                        <td>{{k.amount}}</td>
                        <td>{{k.siteName}}</td>
                        <td>{{k.ts}}</td>
                      </tr>
                    </table>
                  </div>
                </vue-seamless-scroll>
                <div class="table_button">
                  <!-- <el-button size="mini" @click="this.$router.push('/map')">快捷买入</el-button>
                  <el-button size="mini" @click="this.$router.push('/map')">快捷卖出</el-button> -->
                  <el-button size="mini" @click="loading=true,initdata()">刷新行情</el-button>
                </div>
              </div>

              <div class="gyslist">
                <div class="deal_title">
                  <strong>优质供应商</strong>
                </div>
                <div class="imglist">
                  <img v-for="(item,index) in shopList" :key="index"
                 :src="item.logo" alt="" @click="toSpecial(item)">
                </div>
              </div>

              <div class="klist">
                <div class="left_cont">
                  <div class="choose">
                    <ul class="choose_btn_par">
                      <li class="choose_btn_child" v-for="(item,index) in btnlist" :key="index">
                        <button class="child_btn"
                        :class="isaclass(item)?'acrbtn':''"
                        @click="addclass(item)"
                        >{{item}}</button>
                      </li>
                    </ul>
                  </div>
                  <div id="figure">
                    <k-line :id="dataId"></k-line>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>

  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue-demi'
import Player from 'xgplayer'
import http from '../../api/http'
import Footer from '../index/Footer.vue'
import Bang from '../index/videozibang/Bang.vue'
import KLine from '../../components/kline/KLine.vue'
import moment from 'moment'
// import { ElMessage } from 'element-plus'
// import VideoTest from '../../views/index/videozibang/Video.vue'

// 获取成交明细
const getSuccessOrderList = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderList', data)
}
// 获取热门话题
const queryPopularPostss = (data) => {
  return http.get('/mall-portal/mall/post/queryPopularPosts', data)
}
// 获取首页帖子
const queryPostByPage = (data) => {
  return http.get('/mall-portal/mall/post/queryPostByPage', data)
}
// 查询关键词一级分类
const queryForumFirstLevelClassification = (data) => {
  return http.get('/mall-portal/mall/post/queryForumFirstLevelClassification')
}
// 关键词二级查询子节点
const queryChildNodeByParentId = (parentId) => {
  return http.get(`/mall-portal/mall/post/queryChildNodeByParentId/${parentId}`)
}
// 按关键词查询资讯
const searchForPostsOrNewsByKeyword = (data) => {
  return http.get('/mall-portal/mall/post/searchForPostsOrNewsByKeyword', data)
}
// 按专区查
const searchForPostsOrNewsByCoffeeArea = (data) => {
  return http.get('/mall-portal/mall/post/searchForPostsOrNewsByCoffeeArea', data)
}
// 查询供应商
const umscompanyinfo = (data) => {
  return http.post('/mall-merchant/search/umscompanyinfo/list', data)
}
// 查预售视频
const searchVideo = (data) => {
  return http.get('/mall-portal/product/search', data)
}
const headWenzi = [
  { name: '首页', path: '/' },
  { name: '资讯', path: '/coffee/information' }
  // { name: '直播', path: '/live' },
  // { name: '咖啡社区', path: '/coffee' },
  // { name: '会员', path: '/coffee/user' }
]

const tableTitle = ['商品名称', '成交价格', '成交量', '地区', '成交时间']
export default {
  name: 'coffeeCommunity',
  data () {
    return {
      nowTime: '',
      loading: ref(true), // 加载框
      loadingtieziList: ref(true), // 帖子loading
      loadingUserList: ref(true), // 人气用户loading
      loadingRemenList: ref(true), // 热门话题loading
      // checkAll: ref(false), // 多选框

      // 底部分页
      totalnum: ref(100), // 存放总数据个数
      pagesize: ref(10), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页

      activeName: ref('coffee'),
      searValue: ref(''), // 搜索框内容
      searHuaTi: ref(['瑰夏', '精品咖啡', '生豆', '杯测']), // 话题内容
      timeList: ref([{
        city: '北京时间',
        time: '2022 02 11'
      }, {
        city: '伦敦时间',
        time: '2022 02 11'
      }, {
        city: '纽约时间',
        time: '2022 02 11'
      }, {
        city: '悉尼时间',
        time: '2022 02 11'
      }]),
      searGuanJianCi: ref([]), // 关键词索引
      GuanJianCiList: ref([]), // 关键词list
      remenList: ref([]), // 话题列表

      treeList: ref([
        {
          label: '资讯中心',
          children: [
          ]
        },
        {
          label: '产区',
          children: [
          ]
        }
      ]), // 咖啡左侧list
      tieziList: ref([]), // 帖子的内容

      shopList: reactive([]),
      tableCont: ref([]),

      btnlist: ref([
        '昆山港',
        '纽约期货',
        '大版期货',
        '新加坡商品',
        '伦敦期货',
        'Three'
      ]),
      isactbtn: ref(['新加坡商品', '昆山港']),
      dataId: ''
    }
  },
  components: { Footer, Bang, KLine },
  computed: {
    optionHover () {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async initdata () { // 初始化成交明细
      const data = {
        rankingClassification: 3
      }
      const getList = await getSuccessOrderList(data)
      // console.log(getList)
      this.tableCont = getList.data.data
      this.loading = false
    },
    async articles (num) { // 初始化文章
      const page = {
        pageSize: 15,
        pageNum: num,
        postOrInformation: 1
      }
      const articlesList = await queryPostByPage(page)
      if (articlesList.data.code === 200) {
        // console.log(articlesList)
        this.tieziList = articlesList.data.data.list
        this.totalnum = articlesList.data.data.total

        this.loadingtieziList = false
      }
      // console.log(articlesList)
    },
    async HotTopics () { // 初始化热门话题
      const data = {
        pageNum: 1,
        pageSize: 6,
        postOrInformation: 1
      }
      const res = await queryPopularPostss(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.remenList = res.data.data.list
        this.remenList.forEach(item => {
          // console.log(item)
          item.establishTime = moment(item.establishTime).format('YYYY-MM-DD')
        })
        console.log(this.remenList)
        this.loadingRemenList = false
      }
    },
    async initGongYingData () {
      const data = {
        pageNum: 1,
        pageSize: 6
      }
      const res = await umscompanyinfo(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.shopList = res.data.data.list
        this.loading = false
      }
    },
    async GuanJianCiInit () { // 关键词一级分类
      const res = await queryForumFirstLevelClassification()
      console.log(res)
      if (res.data.code === 200) {
        this.searGuanJianCi = res.data.data
        let zires = ''
        this.GuanJianCiList = []
        for (const i of this.searGuanJianCi) {
          // console.log(i)
          // console.log(zires)
          // this.GuanJianCiList.push(zires.data.data)
          // zires = ''
          if (i.name === '资讯中心') {
            // this.guanjianzi = item
            // this.initDataTwo(item.id)
            zires = await queryChildNodeByParentId(i.id)
            if (zires.data.code === 200) {
              console.log(zires)
              // this.GuanJianCiList = res.data.data
              const arr = []
              // console.log(resdata.data.data)
              zires.data.data.forEach(i => {
                arr.push({
                  label: '— ' + i.name,
                  id: i.id,
                  status: 0
                })
              })
              this.treeList[0].children = arr
              console.log(arr)
            }
          }
          if (i.name === '产区') {
            // this.guanjianzi = item
            // this.initDataTwo(item.id)
            zires = await queryChildNodeByParentId(i.id)
            if (zires.data.code === 200) {
              console.log(zires)
              // this.GuanJianCiList = res.data.data
              const arr = []
              // console.log(resdata.data.data)
              zires.data.data.forEach(i => {
                arr.push({
                  label: '— ' + i.name,
                  id: i.id,
                  status: 1
                })
              })
              this.treeList[1].children = arr
              console.log(arr)
            }
          }
        }
        console.log(this.GuanJianCiList)
      }
    },
    toPublications () { // 发表文章
      this.$router.push({
        path: '/coffee/publications',
        query: {
          postOrInformation: '1'
        }
      })
    },
    handleCurrentChange (val) { // 分页
      console.log(`current page: ${val}`)
      this.currentPage = val
      console.log(this.currentPageStatus)
      if (this.currentPageStatus === '1') {
        // console.log(111)
        this.initGuanjianci()
      } else if (this.currentPageStatus === '0') {
        // console.log(222)
        this.articles(this.currentPage)
      } else if (this.currentPageStatus === '2') {
        this.initZhuanQu()
      } else if (this.currentPageStatus === undefined) {
        this.articles(this.currentPage)
      }
    },
    handleClick () { // 点击切换

    },
    tieziDetails (item) { // 查看资讯详情
      console.log(item)
      this.$router.push({
        path: '/coffee/informationArticle',
        query: {
          id: item.id
        }
      })
    },
    isaclass (item) {
      for (const i of this.isactbtn) {
        if (i === item) {
          return true
        }
      }
    },
    addclass (item) {
      for (const i of this.isactbtn) {
        if (i === item) {
          this.isactbtn.splice(this.isactbtn.indexOf(i), 1)
          return
        }
      }
      this.isactbtn.push(item)
      this.isactbtn = Array.from(new Set(this.isactbtn))
      console.log(this.isactbtn)
    },
    getOptions () {
      this.dataId = 1
    },
    async handleNodeClick (e) { // 点击分类
      console.log(e)
      this.loadingtieziList = true
      this.currentPage = 1
      this.currentPageStatus = '1'
      this.GuanListValueId = e.id
      if (e.status === 0) { // 资讯中心
        if (e.id) {
          this.initGuanjianci(e)
        } else {
          this.currentPageStatus = '0'
          this.articles(1)
        }
      } else { // 产区
        if (e.id) {
          this.initZhuanQu(e)
        } else {
          this.currentPageStatus = '0'
          this.articles(1)
        }
      }
    },
    async initGuanjianci (e) {
      this.loadingtieziList = true
      const data = {
        pageSize: 15,
        pageNum: this.currentPage || 1,
        postOrInformation: 1,
        keyWords: e.id || this.GuanListValueId
      }
      const res = await searchForPostsOrNewsByKeyword(data)
      console.log(res)
      if (res.data.code === 200) {
        this.tieziList = res.data.data.list
        this.totalnum = res.data.data.total
      }
      this.loadingtieziList = false
    },
    async initZhuanQu (e) {
      console.log(e)
      this.loadingtieziList = true
      const data = {
        pageSize: 15,
        pageNum: this.currentPage || 1,
        postOrInformation: 1,
        coffeeArea: e.id || this.GuanListValueId
      }
      const res = await searchForPostsOrNewsByCoffeeArea(data)
      console.log(res)
      if (res.data.code === 200) {
        this.tieziList = res.data.data.list
        this.totalnum = res.data.data.total
      }
      this.loadingtieziList = false
    },
    toSpecial (item) {
      console.log(item)
      if (!item.status) {
        this.$router.push({
          path: '/special',
          query: {
            memberId: item.memberId
          }
        })
      }
    }
  },
  created () {
  },
  mounted () {
    const that = this
    this.timer = setInterval(() => {
      that.nowTime = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
    })
    this.articles(1) // 初始化文章
    this.HotTopics() // 初始化热门话题
    this.initdata() // 初始化成交明细
    this.GuanJianCiInit() // 关键词一级分类
    this.initGongYingData() // 查询供应商
  },
  beforeUnmount: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }

    const getPic = async () => {
      const data = {
        pageSize: 1,
        pageNum: 1,
        manorPreSale: 0,
        previewStatus: 1
      }

      const res = await searchVideo(data)
      console.log(res)
      if (res.data.code === 200) {
        return res.data.data
      }
      // console.log(videoData)
    }
    onMounted(() => {
      getPic().then(res => {
        console.log(res)
        if (res.total !== 0) {
          const player = new Player({
            id: 'video',
            autoplay: false,
            width: 347,
            height: 256,
            url: res.list[0].videoAddress || 'http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
            poster: res.list[0].pic || 'https://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg'
            // playbackRate: [0.5, 0.75, 1, 1.5, 2] // 传入倍速可选数组
          })
          return player
        } else {
          const player = new Player({
            id: 'video',
            autoplay: false,
            width: 347,
            height: 256,
            url: 'http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
            poster: 'https://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg'
            // playbackRate: [0.5, 0.75, 1, 1.5, 2] // 传入倍速可选数组
          })
          return player
        }
      }).catch(err => {
        console.log(err)
      })
    })
    return {
      headWenzi,
      tableTitle,
      handleSizeChange
    }
  }
}
</script>

<style lang='scss' scoped>
//@import ''; 引入公共css类
.main{
  width: 100%;
  background: #f2efed;
  // height: 800px;
  font-family: Microsoft YaHei;
  color: #333333;
  .head{
    height: 30px;
    background: #FFFFFF;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      font-size: 14px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        color: #333333;
        cursor: pointer;
        .na-text{
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
      li:nth-last-child(3),li:nth-last-child(2),li:last-child{
        float: right;
        a{
          color: #666;
        }
      }
    }
  }
  .body{
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: 20px;
    .body_title{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      #sear{
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-input{
          flex: 1;
          #searin{
            box-sizing: border-box;
            background: 0;
            // flex: 1;
            width: 490px;
            height: 40px;
            // padding: 0 15px;
            outline: none;
            border: 1px solid;
          }
          #searin::-webkit-input-placeholder {
            color: #999999;
          }
          #searin:-moz-placeholder {
            color: #999999;
          }
          #searin:-ms-input-placeholder {
            color: #999999;
          }
          #searin::-ms-input-placeholder{
            color: #999999;
          }
        }
        .sou{
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
          width: 87px;
          height: 40px;
          background: #4f2300;
        }
      }
      img{
        width: 165px;
        height: 135px;
      }
    }
    .body_cont{
      .cont_sear{
        .sear_title{
          .sear_time{
            display: flex;
            height: 41px;
            background: #666666;
            border: 1px solid #FFEAB7;
            color: #FFFFFF;
            .time_ti{
              padding: 0 20px;
              font-size: 17px;
              font-weight: bold;
              color: #666666;
              line-height: 41px;
              margin-right: 20px;
            }
            ul{
              display: flex;
              padding: 0 20px;
              flex: 1;
              align-items: center;
              justify-content: space-between;
              li{
                display: flex;
                align-items: center;
                .el-icon{
                  font-size: 30px;
                  color: #f0ad11;
                }
                p{
                  font-size: 14px;
                }
                iframe{
                  // color: #fff;
                }
              }
            }
          }
          .sear_body{
            display: flex;
            font-size: 14px;
            .sear_left{
              min-width: 340px;
              flex: 1;
              width: 29%;
              padding: 0 15px 20px;
              h3{
                display: flex;
                height: 40px;
                font-weight: bold;
                line-height: 40px;
                align-items: center;
                .el-icon{
                  font-size: 33px;
                }
                strong{
                  font-size: 20px;
                }
              }
              ul{
                width: 100%;
                display: flex;
                flex-flow: column;
                li{
                  cursor: pointer;
                  // width: 100%;
                  flex: 1;
                  line-height: 45px;
                  font-size: 18px;
                  white-space:nowrap; // 一行显示
                  text-overflow: ellipsis; // 溢出变为省略号
                  overflow: hidden;
                }
              }
            }
            .sear_center{
              padding: 0 25px 20px;
              flex: 1;
              border-left: 4px solid #f2efed;
              border-right: 4px solid #f2efed;
              h3{
                display: flex;
                align-items: center;
                img{
                  width: 33px;
                  height: 33px;
                }
                // display: inline-block;
                height: 40px;
                font-size: 20px;
                font-weight: bold;
                // line-height: 40px;
              }
              #video{
                min-width: 347px;
                margin: 20px 0;
              }
            }
            .sear_right{
              width: 450px;
              // flex: 1;
              padding: 0 15px 20px;
              h3{
                img{
                  width: 33px;
                  height: 33px;
                }
                display: inline-block;
                height: 40px;
                font-size: 20px;
                font-weight: bold;
                line-height: 40px;
              }
            }
          }
        }
      }
      .cont_list{
        .list_title{
          width: 770px;
          height: 32px;
          background: #F1F1F1;
          border: 1px solid #B5B4B4;
          line-height: 32px;
          .el-row{
            width: 100%;
            height: 100%;
            .el-col{
              font-size: 19px;
              font-weight: bold;
              .el-checkbox{
                .el-checkbox__label{
                  font-size: 19px;
                  font-weight: bold;
                }
              }
              .el-button{
                // width: 110px;
                background: #FFB400;
                color: #FFFEFE;
                border-radius: 10px;
              }
            }
          }
        }
        .list_body{
          // height: 40px;
          line-height: 40px;
          .el-row{
            padding: 0 10px;
            display: flex;
            .left_list{
              min-height: 200px;
              box-sizing: border-box;
               .el-tree{
               /deep/ // font-size: 18px;
                .el-tree-node{
                  font-size: 18px;
                  .el-tree-node__content{
                    .el-tree-node__label{
                      font-size: 18px;
                    }
                  }
                }
              }
            }
            .center_list{
              border-right: 4px solid #f2efed;
              border-left: 4px solid #f2efed;
              // margin-left: 10px;
              // padding: 0 !important;
              .el-row{
                padding: 0;
                .el-col{
                  white-space:nowrap; // 一行显示
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                .el-col-12{
                  cursor: pointer;
                }
              }
              .showfooter{
                height: 100px;
                // background: red;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-pagination{
                  // line-height: 100px;
                  .el-pager{
                    margin: 0 20px;
                    .number,.btn-quicknext{
                      margin: 0 5px;
                      width: 40px;
                      height: 40px;
                      border: 1px solid transparent;
                      line-height: 40px;
                    }
                    .active{
                      background: #ffffff;
                      color: #C6824E;
                      border-color: #C6824E;
                    }
                    .number:hover{
                      color: #C6824E;
                    }
                    .el-icon{
                      height: 40px;
                      line-height: 40px;
                    }
                  }
                  .btn-prev,.btn-next,.el-pagination__jump{
                    height: 40px;
                    line-height: 40px;
                    .el-input{
                      height: 40px;
                      .el-input__inner{
                        height: 40px;
                      }
                    }
                  }
                }
              }
            }
            .right_list{
              flex: 1;
              padding: 0 !important;
              margin-top: -34px !important;
              .deallist{
                // width: 100%;
                width: 420px;
                box-sizing: border-box;
                border-left: 4px solid #f2efed;
                margin-left: -4px !important;
                .deal_title{
                  width: 100%;
                  box-sizing: border-box;
                  padding: 0 20px;
                  height: 35px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  strong{
                    font-weight: bold;
                    color: #333333;
                    font-size: 19px;
                  }
                  span{
                    font-size: 14px;
                  }
                }
                #table_head{
                  margin-top: 10px;
                  width: 100%;
                  tr{
                    width: 100%;
                    font-size: 12px;
                    display: flex;
                    th{
                      flex: 1;
                      background: #4f2300;
                      font-weight: bold;
                      color: #FFFFFF;
                    }
                    .thname{
                      min-width: 70px;
                    }
                  }
                }
                .seamless-warp{
                  height: 240px;
                  overflow: hidden;
                    #table_body{
                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    table{
                      font-size: 12px;
                      .table_tr{
                        box-sizing: border-box;
                        flex: 1;
                        display: flex;
                        height: 30px;
                        line-height: 30px;
                        td{
                          text-align: center;
                          // display: flex;
                          border-bottom: 1px solid #f2efed;
                          flex: 1;
                          white-space:nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        }
                      }
                    }
                  }
                }
                .table_button{
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                }
              }
              .gyslist{
                width: 420px;
                .deal_title{
                  padding: 0 20px;
                  height: 50px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  strong{
                    font-weight: bold;
                    color: #333333;
                    font-size: 19px;
                  }
                }
                .imglist{
                  display: flex;
                  flex-flow: wrap;
                  justify-content: space-around;
                  img{
                    cursor: pointer;
                    padding: 20px;
                    // height: 80px;
                    width: 80px;
                    border-radius: 50%;
                  }
                }

              }
              .klist{
                // width: 100%;
                width: 420px;
                .left_cont{
                  display: flex;
                  flex-direction: column;
                  width: 420px;
                  // padding: 10px;
                  box-sizing: border-box;
                  // padding-right: 0;
                  .choose{
                    .choose_btn_par{
                      display: flex;
                      flex-wrap: wrap;
                      // justify-content: space-between;
                      .choose_btn_child{
                        margin: 5px 10px;
                        // flex-basis: 33.33%;
                        .child_btn{
                          border: none;
                          border-bottom:1px solid rgba(166, 181, 196, 0.123);
                          display: block;
                          margin: 0 auto;
                          width: 110px;
                          height: 26px;
                          line-height: 26px;
                          background: #ffffff;
                          border-radius: 4px 0px 0px 4px;
                        }
                        .acrbtn{
                          background: #f2f2f2;
                        }
                      }
                    }
                  }
                  #figure{
                    height: 400px;
                    width: 420px;
                    // background: green;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .divfooter{
    background: #171717;
  }
}
</style>
